import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { deleteData, fetchData } from "../../../api/api";
import ProgramCard from "../../../components/cards/program-card/program-card.component";
import { Row, Col, Table } from "react-bootstrap";
import { Facebook } from "react-content-loader";
import ProfileModal from "../../../components/modal/profile-modal/profile-modal.component";
import SittingModal from "../../../components/modal/sitting-modal/sitting-modal.component";
import moment from "moment";
import Select from "../../../components/form/select/select.component";
import useAccessLevels from "../../../hooks/useAccessLevels";
import ExportButton from "../../../components/export-button/export-button.component";
import InputField from "../../../components/form/input-field/input-field.component";
const StudentSittingsPage = (props) => {
  const [currentUser] = useGlobal("currentUser");

  const { data: sittingsData, isValdating : isLoadingSittings} = useSWR(
    `tuition/sitting?query=`
  );

  const [state, setState] = useState({
    query: "",
    status: "",
    loading: true,
    openModal: false,
    activeSitting: null,
  });


  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Registration-Report");

  const [selectedSitting,setSelectedSitting]= useState(null)
  const [startDate,setStartDate]= useState("")
   const [endDate,setEndDate]= useState("")
  const sittingsOptions =
    (sittingsData &&
      sittingsData?.sittings?.map((s) => ({
        label: s.description,
        value: s,
      }))) ||
    [];


    function formatDateForBackend(date) {
      return new Date(date).toISOString() //.replace("Z", ""); // Removes 'Z' to avoid UTC conversion
    }

    // Example Usage
    const startDate_ = startDate ?  formatDateForBackend(startDate) :""; // 2024-06-04T01:35:00.000
    const endDate_ = endDate?  formatDateForBackend(endDate) :"";

  return (
    <div>
      <PageLayout
        activeLink="registration-report"
        parentMenu="registration-report"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24 mb-3 ">Registration Report </div>
          <div className="d-flex gap-3 flex-wrap align-center">
            <div className="col-12 col-md-8 col-lg-4">
              <Select
                small
                placeholder={"Select Sitting"}
                options={sittingsOptions}
                onChange={(selected) => {
                  setSelectedSitting(selected?.value);
                }}
              />
            </div>
            <div>
              <InputField
                classes={"small"}
                type="datetime-local"
                value={startDate}
                onChange={(ev) => {
                  console.log(ev.target.value);
                  setStartDate(ev.target.value);
                }}
              />
            </div>
            <div>
              <InputField
                classes={"small"}
                type="datetime-local"
                value={endDate}
                onChange={(ev) => setEndDate(ev.target.value)}
              />
            </div>
            <div className="mt-1">
              {(selectedSitting && startDate && endDate) && (
                <ExportButton
                  url={`tuition/sitting/registrations/export?sittingID=${
                    selectedSitting?.id
                  }&program=${
                    selectedSitting?.programID
                  }&startDate=${encodeURIComponent(
                    startDate_
                  )}&endDate=${encodeURIComponent(endDate_)}`}
                  fileName={`${selectedSitting?.description}_${selectedSitting?.program?.name} Report`}
                />
              )}
            </div>
            {/* <div>
              {state.query?.length > 0 && (
                <span
                  className="font-bold text-18 text-danger pointer"
                  onClick={() => {
                    setLoadData(true);
                  }}
                >
                  Load Sittings
                </span>
              )}
            </div> */}
          </div>
         
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(StudentSittingsPage);
