const accessLevels = [
  "Students",
  "Sittings",
  "Student-Registrations",
  "Registration-Report",
  "Payments",
  "Subjects",
  "Book-store",
  "Complaints",
  "Job-portal",
  "Users",
  "Settings",
];

const useAccessLevels = (userAccessLevels) => {
  const hasAccess = (accessLevel) => {
    let access = { read: false, write: false };
    if (
      userAccessLevels
        ?.toLowerCase()
        .includes(`${accessLevel?.toLowerCase()}_write`)
    ) {
      access = { read: true, write: true };
    } else if (
      userAccessLevels
        ?.toLowerCase()
        ?.includes(`${accessLevel?.toLowerCase()}_read`)
    ) {
      access = { read: true, write: false };
    }

    return access;
  };

  const accessRoutes = () => {
    let levels = userAccessLevels?.toLowerCase() || "";

    let availableRoutes = accessLevels?.filter(
      (accessLevel) =>
        levels.includes(`${accessLevel?.toLowerCase()}_write`) ||
        levels.includes(`${accessLevel?.toLowerCase()}_read`)
    );

    return availableRoutes;
  };

  return {
    accessLevels,
    accessRoutes,
    hasAccess,
  };
};

export default useAccessLevels;
